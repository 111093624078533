<template>
  <div>
    <lms-card :key="tab">
      <lms-card-table-filters>
        <v-row>
          <v-col cols="12" sm="12" md="4">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('labels.search')"
              outlined
              hide-details
              v-model="filters.search"
            />
          </v-col>
          <v-col cols="12" sm="12" md="4" v-allowed="[userTypes.ADMIN]">
            <MasterSelect
              keep-height
              placeholder
              no-counter
              optional
              icon="mdi-map-marker-outline"
              v-model="filters.master_ids"
            />
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-select
              prepend-inner-icon="mdi-checkbox-marked-outline"
              placeholder="Status"
              elevation="0"
              outlined
              :items="statusOptions"
              item-text="label"
              item-value="value"
              clearable
              v-model="filters.status"
            />
          </v-col>
        </v-row>
      </lms-card-table-filters>

      <v-data-table
        class="elevation-0"
        :footer-props="defaultFooterProps"
        :headers="headers"
        :items="users"
        :options.sync="options"
        :server-items-length="usersCount"
        :page="filters.page"
        hide-default-header
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        show-expand
        item-key="_id"
        @item-expanded="getStudentData"
      >
        <template v-slot:[`item.name`]="{ item }">
          <div class="f-users-table-item">
            <div
              class="f-users-table-item--name"
              :inner-html.prop="highlight(item.name, filters.search)"
            />
            <div
              class="f-users-table-item--email"
              :inner-html.prop="highlight(item.email, filters.search)"
            />
          </div>
        </template>
        <template v-slot:[`item.masters`]="{ item }">
          {{ getMasterTitle(item) }}
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ getFormatedDate(item) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <lms-data-table-btn @click="view(item._id)">
            <v-icon>mdi-account-details-outline</v-icon>
          </lms-data-table-btn>
          <lms-data-table-btn @click="edit(item._id)">
            <v-icon>mdi-pencil-outline</v-icon>
          </lms-data-table-btn>
          <lms-data-table-btn @click="remove(item._id)">
            <v-icon>mdi-delete-outline</v-icon>
          </lms-data-table-btn>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <row-courses-details :studentID="item._id" loginAs />
          </td>
        </template>
      </v-data-table>
    </lms-card>
  </div>
</template>

<script>
import LmsDataTableFilter from '../shared/DataTableFilter.vue';
import LmsDataTableSearch from '../shared/DataTableSearch.vue';
import MasterSelect from '../shared/MasterSelect.vue';
import RowCoursesDetails from '../Reports/RowCoursesDetails.vue';
import moment from 'moment';
import userStatuses from '../../data/userStatuses';
import { KEYS as userTabsOptions } from '../../data/userTabsFilterOptions';
import { UserType } from '../../helpers/user_utils';
import { defaultFooterProps } from '../../helpers/data_table';

export default {
  props: ['tab'],
  components: {
    LmsDataTableSearch,
    LmsDataTableFilter,
    MasterSelect,
    RowCoursesDetails,
  },
  data: () => ({
    defaultFooterProps,
    search: '',
    master_ids: [],
    status: '',
    filters: {
      master_ids: [],
      search: '',
      page: 1,
      limit: defaultFooterProps['items-per-page-options'][0],
      type: userTabsOptions.STUDENTS,
      status: 'all',
      sortBy: '',
      sortDesc: false,
      fields: ['name', 'status', 'email', 'createdAt', 'masters'],
    },
    listType: UserType.Student,
    options: {},
    showFilter: false,
    showPending: false,
    expanded: [],
    singleExpand: true,
  }),
  computed: {
    statusOptions() {
      const statusOptions = [];
      for (let status of userStatuses) {
        statusOptions.push({
          label: this.$t(`labels.status.${status}`),
          value: status,
        });
      }
      return statusOptions;
    },
    filterStoreType() {
      return this.$store.state.user.tabActiveType;
    },
    users() {
      return this.translateStatus(this.$store.state.user.list);
    },
    usersCount() {
      return this.$store.state.user.listCount;
    },
    filterOptions() {
      var options = [
        {
          text: this.$t('labels.all'),
          value: this.listType == 'all' ? 'all' : 'pending,enabled',
        },
        {
          text: this.$t('labels.pending'),
          value: 'pending',
        },
        {
          text: this.$t('labels.enableds'),
          value: 'enabled',
        },
      ];
      if (this.listType == 'all') {
        options.push({
          text: this.$t('labels.disableds'),
          value: 'disabled',
        });
        options.push({
          text: this.$t('labels.rejecteds'),
          value: 'rejected',
        });
      }
      return options;
    },
    headers() {
      return [
        {
          text: this.$tt.capitalize(this.$t('reports.student.name')),
          align: 'start',
          value: 'name',
        },
        {
          text: 'Status',
          align: 'end',
          value: 'status',
          width: 150,
        },
        {
          text: 'Master',
          align: 'end',
          value: 'masters',
          width: 150,
        },
        {
          text: this.$tt.capitalize(this.$t('labels.createdAt')),
          align: 'end',
          value: 'createdAt',
          width: 150,
        },
        {
          text: this.$tt.capitalize(this.$t('labels.actions')),
          align: 'end',
          sortable: false,
          value: 'actions',
          width: 180,
        },
        { title: '', value: 'data-table-expand' },
      ];
    },
  },
  watch: {
    '$route.name': {
      handler: function() {
        this.filters.status = 'all';
        this.filters.page = 1;
        this.filters.search = '';

        this.get();
      },
    },
    'filters.search': {
      handler: function() {
        clearTimeout(this.intervalSearch);
        const get = this.get;
        this.intervalSearch = setTimeout(() => {
          get();
        }, 500);
      },
    },
    'filters.status': function() {
      this.filters.page = 1;
      this.get();
    },
    'filters.master_ids': function() {
      this.filters.page = 1;
      this.get();
    },

    options: {
      handler: function(values, old) {
        if (values.page !== old.page) {
          this.filters.page = values.page;
          this.get();
        }
        if (values.itemsPerPage !== old.itemsPerPage) {
          this.filters.limit = values.itemsPerPage;
          this.get();
        }
        try {
          if (
            values.sortBy[0] !== old.sortBy[0] ||
            values.sortDesc[0] !== old.sortDesc[0]
          ) {
            this.filters.sortBy = values.sortBy[0];
            this.filters.sortDesc = values.sortDesc[0];
            this.get();
          }
        } catch (e) {}
      },
      deep: true,
    },
    users: function() {
      if (
        !this.showPending &&
        this.users.length &&
        typeof this.$route.query.status != 'undefined' &&
        this.$route.query.status == 'pending'
      ) {
        this.showPending = true;
        this.filters.status = 'pending';
      }
    },
    filterStoreType(value) {
      this.filters.page = 1;
      this.filters.type = value;
    },
  },
  methods: {
    async getStudentData(itemData) {
      this.$store.dispatch('report/studentData', itemData.item._id);
    },
    getFormatedDate(item) {
      return moment(item.createdAt).format('YYYY-MM-DD');
    },
    getMasterTitle(item) {
      let title = '-';
      if (item.masters[0]) {
        title = item.masters[0].title;
      }

      return title;
    },
    highlight(value, query) {
      return value.replace(
        new RegExp(query, 'ig'),
        "<span class='f-hl'>" + query + '</span>'
      );
    },
    translateStatus(users) {
      users.forEach(u => {
        if (u.status == 'enabled') {
          u.status = this.$t('labels.enabled');
        }
        if (u.status == 'disabled') {
          u.status = this.$t('labels.disabled');
        }
        if (u.status == 'rejected') {
          u.status = this.$t('labels.rejected');
        }
        if (u.status == 'pending') {
          u.status = this.$t('labels.pending');
        }
      });
      return users;
    },
    edit(itemID) {
      // console.log('edit', itemID);
      this.$router.push({
        name: 'user-edit',
        params: {
          id: itemID,
        },
      });

      this.$gtm.trackEvent({
        category: 'users-admin-actions',
        event: 'click',
        action: 'users-click',
        label: 'users-edit',
        value: `edit-${itemID}`,
      });
    },
    get() {
      if (this.filters.status == null) {
        this.filters.status = 'all';
      }
      if (this.$store.state.auth.user.type == UserType.Admin) {
        this.filters.type = userTabsOptions.ALL;
      }
      this.$store.dispatch('user/list', this.filters);
    },
    async remove(itemID) {
      var response = await this.$store.dispatch('dialog/show', {
        title: this.$t('alerts.users.remove.title'),
        content: this.$t('alerts.users.remove.content'),
      });
      if (response) {
        this.$store.dispatch('user/remove', itemID);
        this.$store.dispatch('user/list', this.filters);

        this.$gtm.trackEvent({
          category: 'users-admin-actions',
          event: 'click',
          action: 'users-click',
          label: 'users-remove',
          value: `remove-${id}`,
        });
      }
    },
    view(id) {
      this.$router.push({
        name: 'user-view-profile',
        params: { id },
      });

      this.$gtm.trackEvent({
        category: 'users-admin-actions',
        event: 'click',
        action: 'users-click',
        label: 'users-view',
        value: `view-${id}`,
      });
    },
  },
};
</script>

<style lang="scss">
.f-users-table-item {
  padding: 9px 0;

  .f-hl {
    color: #fab330;
    font-weight: bold;
    text-transform: uppercase;
  }

  .f-users-table-item--name {
    font-weight: 900;
  }

  .f-users-table-item--email {
    font-size: 10px;
    margin-top: -2px;
  }
}
</style>
