<template>
  <div>
    <page-header />

    <f-container topBottom>
      <page-tabs
        v-model="pageTab"
        :items="items"
        v-allowed="[userTypes.ADMIN]"
      />

      <pending-alert-btn />

      <user-list ref="userList" />
    </f-container>
  </div>
</template>

<script>
import PageHeader from '../../components/Users/ListHeader.vue';
import UserList from '../../components/Users/ListUser.vue';
import PendingAlertBtn from '../../components/Users/PendingAlertBtn.vue';
import PageTabs from '../../components/shared/PageTabs.vue';
import { KEYS as userTabsOptions } from '../../data/userTabsFilterOptions';
import { getTabsCounters } from '../../services/users';

export default {
  components: {
    PageHeader,
    UserList,
    PendingAlertBtn,
    PageTabs,
  },
  data: () => ({
    pageTab: 0,
    tabsType: userTabsOptions,
    tabsAppends: {
      students: '',
      fact: '',
      admins: '',
      all: '',
    },
  }),
  computed: {
    items() {
      return [
        {
          icon: 'mdi-school-outline',
          title: 'Students',
          append: this.tabsAppends.students.toString(),
          gtm: 'tab-students',
        },
        {
          icon: 'mdi-account-multiple-outline',
          title: 'FACT',
          append: this.tabsAppends.fact.toString(),
          gtm: 'tab-fact',
        },
        {
          icon: 'mdi-account-multiple-outline',
          title: 'Admins',
          append: this.tabsAppends.admins.toString(),
          gtm: 'tab-admins',
        },
        {
          icon: 'mdi-account-group-outline',
          title: 'All',
          append: this.tabsAppends.all.toString(),
          gtm: 'tab-all',
        },
      ];
    },
  },
  watch: {
    pageTab(value) {
      console.log('pageTab', this.items, value);
      let filterOption;
      switch (value) {
        case 0:
          filterOption = userTabsOptions.STUDENTS;
          break;
        case 1:
          filterOption = userTabsOptions.FACT;
          break;
        case 2:
          filterOption = userTabsOptions.ADMINS;
          break;
        case 3:
          filterOption = userTabsOptions.ALL;
          break;
        default:
          filterOption = userTabsOptions.ALL;
          break;
      }

      this.$store.state.user.tabActiveType = filterOption;
      this.$nextTick(() => {
        this.$refs.userList.get();
      });

      this.$gtm.trackEvent({
        category: 'users',
        event: 'click',
        action: 'users-tab-click',
        label: 'users-tab-click',
        value: `${this.items[this.pageTab].gtm}`,
      });
    },
  },
  created() {
    this.getTabsCounters();
  },
  methods: {
    async getTabsCounters() {
      this.tabsAppends = await getTabsCounters();
    },
  },
};
</script>
