<template>
  <f-page-header :title="title" :prevLinks="[]">
    <template #actions>
      <lms-page-header-action-btn icon="mdi-plus" @click="create">
        {{ $tt.capitalize($t('labels.create')) }}
      </lms-page-header-action-btn>
    </template>
  </f-page-header>
</template>

<script>
export default {
  computed: {
    title() {
      return this.$t('labels.users');
    },
  },
  methods: {
    create() {
      this.$router.push({
        name: 'user-create',
      });
    },
  },
};
</script>
