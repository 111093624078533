<template>
  <div v-if="!showPending" @click="view" class="pending-btn">
    {{ count }} {{ $t('alerts.general.pendindUsers') }}
  </div>
</template>

<script lang="js">
export default {
	data: () => ({
		count: 0
	}),
	computed: {
		showPending() {
			return (
				typeof this.$route.query.status != 'undefined' &&
				this.$route.query.status == 'pending' &&
				this.count

			)
		}
	},
	created(){
		this.getData()
	},
	methods: {
		async getData() {
			this.count = await this.$store.dispatch('user/getPendingUserCount') || 0
		},
		view(){
      this.$gtm.trackEvent({
        category: 'users-admin-actions',
        event: 'click',
        action: 'users-click',
        label: 'users-pending-alert',
        value: 'aprove-users',
      });
			document.location = '/users/student?status=pending'
		}
	}
}
</script>

<style lang="css">
.pending-btn {
  background-color: #fdc031 !important;
  color: black;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 36px;
}
</style>
